import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AnswerKeyItem from './AnswerKeyItem';
import { useTranslation } from 'react-i18next';
import { Button } from '@punchcard/core';
import { IconAdd } from '@punchcard/core/icons';

export enum QuestionType {
	MultipleChoice = 'multiple_choice',
	TrueFalse = 'true_false',
}

interface Question {
	questionType: QuestionType;
	options?: { label: string; value: string; }[];
	correctAnswer: Record<string, boolean>[];
}

interface IProps {
	examIndex: number;
	name: string;
}
const MAX_QUESTIONS = 30;

function AnswerKey(props: IProps) {
	const { name } = props;
	const { control } = useFormContext();
	const { t } = useTranslation();
	const { fields, remove, append } = useFieldArray({
		control,
		name,
	});
	const addExamQuestion = () => {
		if (fields.length === MAX_QUESTIONS) {
			return;
		}
		const multipleChoiceOptions = [
			{ label: 'A', value: 'A' },
			{ label: 'B', value: 'B' },
			{ label: 'C', value: 'C' },
			{ label: 'D', value: 'D' },
		];
		const question: Question = {
			questionType: QuestionType.MultipleChoice,
			options: multipleChoiceOptions,
			correctAnswer: [{ A: false }, { B: false }, { C: false }, { D: false }],
		};
		append(question);
	};

	return (
		<div>
			<div className="fw-bold mb-2">{t('courses.answer_key')}</div>
			{fields.length > 0 ? (
				<div className="bg-white rounded border border-gray-400 overflow-hidden">
					{fields.map((field, index) => (
						<AnswerKeyItem
							index={index}
							key={field.id}
							id={field.id}
							name={`${name}.[${index}]`}
							onRemove={() => remove(index)}
						/>
					))}
				</div>
			) : (
				<div>{t('courses.no_questions')}</div>
			)}
			<Button
				className="btn-outline-primary my-3"
				onClick={addExamQuestion}
				disabled={fields.length === MAX_QUESTIONS}
				icon={<IconAdd className="icon-27" />}
			>
				{t('courses.add_question')}
			</Button>
		</div>
	);
}

export default AnswerKey;