import React from 'react';
import classNames from 'classnames';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label: string | undefined;
	radioClassName?: string;
}

const Radio = (props: IProps) => {
	const { label, className, radioClassName, ...otherProps } = props;
	return (
		<div className={classNames(className, 'form-check')}>
			<label className={classNames('form-check-label', props.disabled && 'disabled')} htmlFor={props.name} onClick={(e) => handleOnClick(e)}>
				<input className={classNames('form-check-input', radioClassName)} type="radio" {...otherProps} />
				{label}
			</label>
		</div>
	);

	function handleOnClick(_e: React.MouseEvent) {
		if (props.onChange !== undefined && props.disabled !== true) {
			props.onChange({} as React.ChangeEvent<HTMLInputElement>);
		}
	}
};

export default Radio;