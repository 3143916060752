/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import Spinner from './Spinner';


interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
	prepend?: string;
	append?: string;
	error?: boolean;
	spinner?: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, IProps>((props, ref) => {
	const { type, className, prepend, error, append, spinner, ...otherProps } = props;
	const InputField =
		<input
			type={type || 'text'}
			id={props.name}
			ref={ref}
			{...otherProps}
			className={classNames('form-control', error && 'is-invalid', className)}
		/>;


	if (append !== undefined || prepend !== undefined || spinner) {
		return (
			<div className={classNames('input-group')}>
				{(prepend !== undefined) && (
					<div className="input-group-prepend">
						<div className="input-group-text">{prepend}</div>
					</div>
				)}
				{InputField}
				{(append !== undefined) && (
					<div className="input-group-append">
						<div className="input-group-text">{append}</div>
					</div>
				)}

				{spinner && <div className="input-group-append">
					<div className="input-group-text bg-white pt-3 pb-3"><Spinner small={true} className="spinner-sm" /></div>
				</div>}

			</div>
		);
	}
	return InputField;
});
