import React, { HTMLAttributeAnchorTarget } from 'react';
import classNames from 'classnames';
import { Link, To } from 'react-router-dom';
import { Spinner } from '@punchcard/core';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	loading?: boolean;
	to?: To;
	target?: HTMLAttributeAnchorTarget;
	icon?: React.ReactElement;
	iconPosition?: 'left' | 'right';
}

export const Button = (props: ButtonProps) => {
	const { to, loading, disabled, className, children, target, icon, iconPosition = 'left', ...otherProps } = props;
	const elementRef = React.useRef<HTMLButtonElement & HTMLAnchorElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
		if (elementRef.current) {
			(elementRef.current as HTMLElement).blur();
		}

		if (otherProps.onClick) {
			otherProps.onClick(event as React.MouseEvent<HTMLButtonElement, MouseEvent>);
		}
	};

	const content = (
		<span className="btn-content mb-0 pb-0">
			{iconPosition === 'left' && (icon || loading) && (
				<div className="me-1 d-flex justify-content-center" style={{ minWidth: 30 }}>
					{icon && !loading && (
						React.cloneElement(icon)
					)}
					{loading === true && (
						<Spinner small={true} />
					)}
				</div>
			)}
			{children}
			{iconPosition === 'right' && (icon || loading) && (
				<div className="me-1 d-flex justify-content-center" style={{ minWidth: 30 }}>
					{icon && !loading && (
						React.cloneElement(icon)
					)}
					{loading === true && (
						<Spinner small={true} />
					)}
				</div>
			)}
		</span>
	);

	if (to !== undefined && disabled !== true) {
		return (
			<Link to={to} className={classNames('btn', className)} target={target}
				ref={elementRef} onClick={handleClick}>
				{content}
			</Link>
		);
	}

	return (
		<button
			ref={elementRef}
			className={classNames('btn', className, loading === true && 'btn-loading')}
			disabled={disabled === true || loading === true}
			onClick={handleClick}
			{...otherProps}
		>
			{content}
		</button>
	);
};

