import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IconDelete } from '@punchcard/core/icons';
import classNames from 'classnames';
import { ExamRadioGroup, FormGroup } from '@punchcard/core';
import { QuestionType } from './AnswerKey';
import { useTranslation } from 'react-i18next';

interface IProps {
	className?: string;
	name: string;
	onRemove: () => void;
	index: number;
	id: string;
	correctAnswer?: string;
}

const trueFalseOptions = [
	{ label: 'A', value: 'A' },
	{ label: 'B', value: 'B' },
];

const multipleChoiceOptions = [
	{ label: 'A', value: 'A' },
	{ label: 'B', value: 'B' },
	{ label: 'C', value: 'C' },
	{ label: 'D', value: 'D' },
];

function AnswerKeyItem(props: IProps) {
	const { className, name, onRemove, index, id } = props;
	const { control, watch, setValue } = useFormContext();
	const { t } = useTranslation();
	const type: QuestionType = watch(`${name}.questionType`);
	setValue(`${name}.questionNumber`, index + 1);
	const questionTypes = [
		{ value: QuestionType.MultipleChoice, label: t('courses.multiple_choice') },
		{ value: QuestionType.TrueFalse, label: t('courses.true_false') },
	];
	return (
		<div
			className={classNames(
				'px-3 py-1',
				{ 'bg-white': index % 2 !== 0 },
				{ 'bg-quaternary-300': index % 2 === 0 },
				className
			)}
		>
			<div className="row">
				<div className="col-6">
					<div className="d-flex align-items-center">
						<span className="fw-bold" style={{ width: 15 }}>
							{index + 1}
						</span>
						<Controller
							control={control}
							name={`${name}.questionNumber`}
							render={({ field }) => (
								<input
									{...field}
									type="hidden"
								/>
							)}

						/>
						<Controller
							control={control}
							name={`${name}.questionType`}
							rules={{
								required: t('courses.answer_is_required')
							}}
							render={({ field }) => {
								return (
									<div className="mb-0 ms-3 d-flex flex-fill">
										<select
											{...field}
											className="rounded-5 ms-3 py-1 px-3 text-gray-800 flex-fill"
										>
											{questionTypes.map((type) => (
												<option key={`option-${id}-${type.value}`} value={type.value}>
													{type.label}
												</option>
											))}
										</select>
									</div>
								);
							}}
						/>
					</div>

				</div>
				<div className="col-6">
					<div className="d-flex align-items-center justify-content-between">
						<Controller
							control={control}
							name={`${name}.correctAnswer`}
							rules={{
								required: t('courses.answer_is_required')
							}}
							render={({ field, fieldState }) => (
								<FormGroup field={field} fieldState={fieldState} className="mb-0 ms-3">
									<ExamRadioGroup
										value={field.value}
										onChange={field.onChange}
										options={getOptionsForQuestionType(type)}
										className="me-4"
										correctValue={field.value}
										isStudent={false}
									/>
								</FormGroup>
							)}
						/>
						<IconDelete className="btn-ghost-primary" style={{ width: 18, height: 18 }} onClick={onRemove} />
					</div>
				</div>
			</div>
		</div>
	);
	function getOptionsForQuestionType(questionType: QuestionType) {
		switch (questionType) {
			case QuestionType.MultipleChoice:
				return multipleChoiceOptions;
			case QuestionType.TrueFalse:
				return trueFalseOptions;
			default:
				return [];
		}
	}
}

export default AnswerKeyItem;