import React from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Page, Button, FormGroup, Input, Checkbox, LoadingScreen } from '@punchcard/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { useTranslation } from 'react-i18next';
import { IconInfo, IconAdd, IconSave } from '@punchcard/core/icons';
import CourseUnitComponent from 'components/Course/CourseUnit'; // Import the CourseUnitComponent
import EntityHeader from '@punchcard/core/components/EntityHeader';
import { PickListSelect } from 'components/core/PicklistSelect';
import { preventWindowClose } from 'utils/utils';
import CourseResources from 'components/Course/CourseResources';

const CoursePage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const { t } = useTranslation();

	const [loading, setLoading] = React.useState<boolean>(false);
	const methods = useForm<AdminCourseDTO>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			courseName: '',
			courseNumber: '',
			additionalMaterials: false,
			additionalTech: false,
			permissionSlipRequired: false,
			courseStates: [],
			courseTags: [],
			courseSubject: '',
			courseGrades: [],
			courseLevels: [],
			courseLexiles: [],
			courseModalities: [],
			courseUnits: [],
			courseDocuments: [],
		},
	});
	const courseId: number = Number(params.courseId);
	const { handleSubmit, control, reset, getValues, formState } = methods;
	const { append, fields, remove } = useFieldArray({ control, name: 'courseUnits', keyName: 'courseUnits' });

	React.useEffect(() => {
		if (formState.isDirty) {
			window.addEventListener('beforeunload', preventWindowClose);
			window.addEventListener('popstate', preventWindowClose);
		}
		return () => {
			window.removeEventListener('beforeunload', preventWindowClose);
			window.removeEventListener('popstate', preventWindowClose);
		};
	}, [formState.isDirty]);

	React.useEffect(() => {
		async function fetchData() {
			setLoading(true);
			const course = await adminAPI.course.getCourse(courseId);
			reset(course, { keepDirty: false });
			setLoading(false);
		}
		fetchData();
	}, [courseId, reset]);

	const addCourseUnit = async () => {
		const courseunit: AdminCourseAndUnitIdDTO = await adminAPI.course.postCreateCourseUnitId({ courseId: courseId, courseUnitId: 0, courseLessonId: 0, courseActivityId: 0, assessmentType: '' });
		append({
			id: courseunit.courseUnitId,
			courseId: courseunit.courseId,
			unitName: '',
			unitNumber: '',
			pageNumbers: '',
			courseDocuments: [],
			courseLessons: [],
			partNumber: '',
			courseAssessments: [],
		});
	};

	const deleteCourse = async () => {
		const confirmed = window.confirm(t('courses.are_you_sure_you_want_to_delete_this_course'));
		if (confirmed) {
			const result = await adminAPI.course.deleteCourse(courseId);
			if (result === true) {
				toast.success(t('courses.course_deleted'));
				navigate('/courses');
			} else {
				toast.error(t('courses.unable_to_delete_course'));
				navigate('/courses');
			}
		}
	};
	const deleteCourseUnit = async (unitIndex: number) => {
		const confirmed = window.confirm(t('courses.are_you_sure_you_want_to_delete_this_unit'));
		if (confirmed) {
			remove(unitIndex);
		}
	};
	const submitData = async () => {
		setLoading(true);
		const courseDTO: AdminCourseDTO = getValues();
		const course = await adminAPI.course.postCourse(courseDTO);
		if (course) {
			toast.success(t('courses.course_saved'));
			navigate(-1);
		} else {
			toast.error(t('courses.unable_to_save_course'));
		}
		setLoading(false);
	};

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<Page className="bg-light scroll-container-wrapper flex-column" title={t('courses.course_details')} >
			<FormProvider {...methods}>
				<div className="scroll-container px-5 py-3 scroll">
					<div className="row">
						<div className="col-lg-7">
							<EntityHeader
								title={t('courses.course_details')}
								deleteEntity={deleteCourse}
								parentRoute={`/courses${location.search}`}
							/>
							<div className="bg-white rounded-3 my-4 border border-quaternary-600">
								<div className="d-flex align-items-center border-bottom border-quaternary-500 py-3 px-4">
									<IconInfo className="me-2 icon-22" />
									<h5>{t('courses.course_information')}</h5>
								</div>
								<div className="pt-3 px-4">
									<Controller
										control={control}
										name="courseName"
										rules={{
											required: t('this_field_is_required'),
										}}
										render={({ field }) =>
											<FormGroup label={t('courses.course_name')} required={true}>
												<Input
													{...field}
												/>
											</FormGroup>
										}
									/>
									<Controller
										control={control}
										name="courseNumber"
										render={({ field }) =>
											<FormGroup label={t('courses.code')}>
												<Input
													{...field}
												/>
											</FormGroup>
										}
									/>
									<div className="d-flex justify-content-between">
										<Controller
											control={control}
											name="additionalMaterials"
											render={({ field, fieldState }) =>
												<FormGroup field={field} fieldState={fieldState}>
													<Checkbox
														label={t('courses.additional_materials')}
														checked={field.value}
														onChange={field.onChange}
													/>
												</FormGroup>
											}
										/>
										<Controller
											control={control}
											name="additionalTech"
											render={({ field, fieldState }) =>
												<FormGroup field={field} fieldState={fieldState}>
													<Checkbox
														label={t('courses.additional_tech')}
														checked={field.value}
														onChange={field.onChange}
													/>
												</FormGroup>
											}
										/>
										<Controller
											control={control}
											name="permissionSlipRequired"
											render={({ field, fieldState }) =>
												<FormGroup field={field} fieldState={fieldState}>
													<Checkbox
														label={t('courses.permission_slip_required')}
														checked={field.value}
														onChange={field.onChange}
													/>
												</FormGroup>
											}
										/>
									</div>

									<Controller
										control={control}
										name="courseStates"
										render={({ field }) =>
											<FormGroup label={t('courses.course_states')} >
												<PickListSelect
													picklistTypeCode="State"
													isMulti={true}
													value={field.value}
													onChange={field.onChange}
												/>
											</FormGroup>
										}
									/>

									<Controller
										control={control}
										name="courseTags"
										render={({ field }) =>
											<FormGroup label={t('courses.course_tags')} >
												<PickListSelect
													picklistTypeCode="CourseTag"
													isMulti={true}
													value={field.value}
													onChange={field.onChange}
												/>
											</FormGroup>
										}
									/>
									<Controller
										control={control}
										name="courseSubject"
										render={({ field }) =>
											<FormGroup label={t('courses.course_subject')}>
												<PickListSelect
													picklistTypeCode="Subject"
													isMulti={false}
													value={field.value}
													onChange={field.onChange}
												/>
											</FormGroup>
										}
									/>
									<Controller
										control={control}
										name="courseGrades"
										render={({ field }) =>
											<FormGroup label={t('courses.course_grades')}>
												<PickListSelect
													picklistTypeCode="Grade"
													isMulti={true}
													value={field.value}
													onChange={field.onChange}
												/>
											</FormGroup>
										}
									/>
									<Controller
										control={control}
										name="courseLevels"
										render={({ field }) =>
											<FormGroup label={t('courses.course_levels')}>
												<PickListSelect
													picklistTypeCode="CourseLevel"
													isMulti={true}
													value={field.value}
													onChange={field.onChange}
												/>
											</FormGroup>
										}
									/>

									<Controller
										control={control}
										name="courseLexiles"
										render={({ field }) =>
											<FormGroup label={t('courses.course_lexiles')}>
												<PickListSelect
													picklistTypeCode="Lexile"
													isMulti={true}
													value={field.value}
													onChange={field.onChange}
												/>
											</FormGroup>
										}
									/>

									<Controller
										control={control}
										name="courseModalities"
										render={({ field }) =>
											<FormGroup label={t('courses.course_modalities')}>
												<PickListSelect
													picklistTypeCode="Modality"
													isMulti={true}
													value={field.value}
													onChange={field.onChange}
												/>
											</FormGroup>
										}
									/>
									<CourseResources
										courseId={courseId}
									/>
								</div>
							</div>

							{fields.map((unit, unitIndex) => (
								<CourseUnitComponent
									key={`courseunit${unit.id}`}
									unitIndex={unitIndex}
									unitId={unit.id ?? 0}
									courseId={unit.courseId}
									removeUnit={() => deleteCourseUnit(unitIndex)}
								/>
							))}
							<Button
								className="btn-outline-primary mt-3"
								onClick={addCourseUnit}
								icon={<IconAdd className="icon-27" />}
							>
								{t('courses.add_unit')}
							</Button>
						</div>
					</div>
				</div>
				<div className="border-top border-quaternary-600 px-5 py-3">
					<Button onClick={handleSubmit(submitData)} className="btn-primary" icon={<IconSave className="icon-27" />}>{t('navigation.save_and_exit')}</Button>
				</div>
			</FormProvider>
		</Page >
	);
};

export default CoursePage;


