import React from 'react';
import { Button } from './Button';
import type { ButtonProps } from './Button';
import classNames from 'classnames';

interface DropdownProps extends ButtonProps {
	options: { label: string; onClick: () => void }[];
}

function Dropdown(props: DropdownProps) {
	const { options, children, className, ...buttonProps } = props;
	const [isOpen, setIsOpen] = React.useState(false);
	function onClick(callback: () => void) {
		callback();
		setIsOpen(false);
	}
	return (
		<div className="dropdown">
			<Button
				className={classNames('btn-secondary dropdown-toggle d-flex', className, isOpen && 'show')}
				type="button"
				data-bs-toggle="dropdown"
				{...buttonProps}
				aria-expanded={isOpen}
				onClick={() => setIsOpen(v => !v)}
			>
				{children}
			</Button>
			<ul className={classNames('dropdown-menu dropdown-menu-end', isOpen && 'show')}>
				{options.map((option) => (
					<li key={option.label.replace(' ', '')}>
						<button className="dropdown-item" onClick={() => onClick(option.onClick)}>{option.label}</button>
					</li>
				))}
			</ul>
		</div>
	);
}
export default Dropdown;