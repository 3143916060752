import React from 'react';
import { FormGroup, Input, Button } from '@punchcard/core';
import { IconAdd, IconClose, IconOpenBook } from '@punchcard/core/icons';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CourseActivityComponent from './CourseActivity';
import adminAPI from 'api/AdminAPI';
import CourseAssessmentComponent from './CourseAssessment';
interface CourseLessonComponentProps {
	unitId: number;
	courseId: number;
	lessonId: number;
	lessonIndex: number;
	removeLesson: (index: number) => void;
	name: string;
}

const CourseLessonComponent = (CourseLessonComponentProps: CourseLessonComponentProps) => {
	const { t } = useTranslation();
	const { control } = useFormContext();
	const { name, lessonIndex, removeLesson, unitId, courseId, lessonId } = CourseLessonComponentProps;
	const { fields: activityFields, append: appendActivity, remove: removeActivity } = useFieldArray({
		control,
		name: `${name}[${lessonIndex}].courseActivities`,
		keyName: 'uid',
	});

	const { fields: assessmentFields, remove: removeAssessment } = useFieldArray({
		control,
		name: `${name}[${lessonIndex}].courseAssessments`,
		keyName: 'uid',
	});

	const deleteCourseActivity = async (index: number) => {
		const confirmed = window.confirm(t('courses.are_you_sure_you_want_to_delete_this_activity'));
		if (confirmed) {
			removeActivity(index);
		}
	};

	const deleteCourseAssessment = async (index: number) => {
		const confirmed = window.confirm(t('courses.are_you_sure_you_want_to_delete_this_assessment'));
		if (confirmed) {
			removeAssessment(index);
		}
	};

	const addCourseActivity = async () => {
		const courseactivity: AdminCourseAndUnitIdDTO =
			await adminAPI.course.postCreateCourseActivityId({ courseId: courseId, courseUnitId: unitId, courseLessonId: lessonId, courseActivityId: 0, assessmentType: 'assessment' });
		appendActivity({
			courseId: courseId,
			courseUnitId: unitId,
			lessonId: lessonId,
			Id: courseactivity.courseActivityId,
			activityTypeId: 0,
			courseLesson: 0,
			activityName: '',
			sortOrder: 0,
			pageNumbers: '',
			courseDocuments: [],
		});
	};

	// const addCourseAssessment = async () => {
	// 	const courseassessment: AdminCourseAndUnitIdDTO = await adminAPI.course.postCreateCourseAssessmentId({
	// 		courseId: courseId,
	// 		courseUnitId: unitId,
	// 		courseLessonId: lessonId,
	// 		courseAssessmentId: 0,
	// 	});
	// 	appendAssessment({
	// 		courseId: courseId,
	// 		courseUnitId: unitId,
	// 		lessonId: lessonId,
	// 		Id: courseassessment.courseAssessmentId,
	// 		courseLesson: 0,
	// 		assessmentName: '',
	// 		sortOrder: 0,
	// 		pageNumbers: '',
	// 		courseDocuments: [],
	// 		gradeValue: 0,
	// 	});
	// };

	return (
		<div className="bg-light px-4 py-1 pb-3 mb-3 rounded-3">
			<div className="d-flex justify-content-between align-items-center mb-3">
				<h6 className="mb-0 d-flex align-items-center">
					<IconOpenBook className="me-1 icon-27" />
					{t('courses.lesson')} {lessonIndex + 1}
				</h6>
				<Button
					className="btn-ghost-primary me-n3 btn-sm"
					onClick={() => removeLesson(lessonIndex)}
					icon={<IconClose className="icon-22" />}
				>
					{t('courses.remove')}
				</Button>
			</div>
			<Controller
				key={`lessonname${lessonIndex}`}
				control={control}
				name={`${name}.${lessonIndex}.lessonName`}
				render={({ field }) => (
					<FormGroup label={t('courses.lesson_name')}>
						<Input
							{...field}
						/>
					</FormGroup>
				)}
			/>
			<Controller
				key={`lessonpagenumber${lessonIndex}`}
				control={control}
				name={`${name}.${lessonIndex}.pageNumbers`}
				render={({ field }) => (
					<FormGroup label={t('courses.pdf_page_range')}>
						<p>{t('courses.use_commas_to_split')}</p>
						<Input
							{...field}
						/>
					</FormGroup>
				)}
			/>

			{activityFields.length > 0 && activityFields.map((item, index) => {
				const typedItem = item as unknown as AdminCourseLessonDTO;
				return (
					<CourseActivityComponent
						key={`activity-${typedItem.id}`}
						name={`${name}.${lessonIndex}.courseActivities`}
						activityIndex={index}
						removeActivity={() => deleteCourseActivity(index)}
					/>
				);
			})}

			{assessmentFields.length > 0 && assessmentFields.map((item, index) => {
				const typedItem = item as unknown as AdminCourseLessonDTO;
				return (
					<CourseAssessmentComponent
						key={`assessment-${typedItem.id}`}
						name={`${name}.${lessonIndex}.courseAssessments`}
						assessmentIndex={index}
						removeAssessment={() => deleteCourseAssessment(index)}
						lessonParent={true}
					/>
				);
			})}
			<Button
				className="btn-outline-primary me-3"
				onClick={addCourseActivity}
				icon={<IconAdd />}
			>
				{t('courses.add_activity')}
			</Button>

			{/* <Button
				className="btn-outline-primary ms-3"
				onClick={addCourseAssessment}
				icon={<IconAdd />}
			>
				{t('courses.add_assessment')}
			</Button> */}
		</div>
	);
};
export default CourseLessonComponent;
