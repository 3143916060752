import React from 'react';
import { FormGroup, Input, Button, ActionIcon, Dropdown } from '@punchcard/core';
import adminAPI from 'api/AdminAPI';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CourseLessonComponent from './CourseLesson';
import { IconAdd, IconBook, IconClose, IconDelete } from '@punchcard/core/icons';
import { toast } from 'react-toastify';
import CourseExam from './CourseExam';
import { QuestionType } from './AnswerKey';
import CourseAssessmentComponent from './CourseAssessment';
interface CourseUnitComponentProps {
	unitIndex: number;
	unitId: number;
	courseId: number;
	removeUnit: (index: number) => void;
}

const CourseUnitComponent = (CourseUnitComponentProps: CourseUnitComponentProps) => {
	const { t } = useTranslation();
	const { control, setValue } = useFormContext();
	const { unitIndex, removeUnit, unitId, courseId } = CourseUnitComponentProps;

	const { fields: lessonFields, append: appendLesson, remove: removeLesson } = useFieldArray({
		control,
		name: `courseUnits[${unitIndex}.courseLessons`,
		keyName: 'uid',
	});

	const { fields: assessmentFields, append: appendAssessment, remove: removeAssessment } = useFieldArray({
		control,
		name: `courseUnits[${unitIndex}.courseAssessments`,
		keyName: 'uid',
	});

	const deleteCourseLesson = async (index: number) => {
		const confirmed = window.confirm(t('courses.are_you_sure_you_want_to_delete_this_lesson'));
		if (confirmed) {
			removeLesson(index);
		}
	};

	const deleteCourseDocument = async (documentId: number) => {
		const confirmed = window.confirm(t('documents.are_you_sure_you_want_to_delete_this_document'));
		if (confirmed) {
			const res = await adminAPI.course.deleteCourseDocument(documentId.toString());
			if (res === true) {
				setValue(`courseUnits.${unitIndex}.courseDocuments`, []);
			}
			return res;
		} else {
			return false;
		}
	};

	const deleteCourseAssessment = async (index: number) => {
		const confirmed = window.confirm(t('courses.are_you_sure_you_want_to_delete_this_assessment'));
		if (confirmed) {
			removeAssessment(index);
		}
	};

	const addCourseLesson = async () => {
		const courseLesson: AdminCourseAndUnitIdDTO = await adminAPI.course.postCreateCourseLessonId({ courseId: courseId, courseUnitId: unitId, courseLessonId: 0, courseActivityId: 0, assessmentType: '' });
		appendLesson({
			id: courseLesson.courseLessonId,
			courseId: courseLesson.courseId,
			courseUnitId: courseLesson.courseUnitId,
			lessonName: '',
			sortOrder: 0,
			pageNumbers: '',
			courseDocuments: [],
			courseActivities: [],
		});
	};

	const addCourseExam = async (assessmentType: string) => {
		const courseassessment: AdminCourseAndUnitIdDTO = await adminAPI.course.postCreateCourseAssessmentId({
			courseId: courseId,
			courseUnitId: unitId,
			courseLessonId: 0,
			courseAssessmentId: 0,
			assessmentType: assessmentType,
		});
		appendAssessment({
			courseId: courseId,
			courseUnitId: unitId,
			lessonId: 0,
			instructions: t('courses.exam_default_instructions'),
			Id: courseassessment.courseAssessmentId,
			courseLesson: 0,
			assessmentName: '',
			assessmentType: assessmentType,
			sortOrder: 0,
			pageNumbers: '',
			courseDocuments: [],
			gradeValue: 0,
			courseAssessmentQuestions: Array.from({ length: 30 }, () => {
				return ({
					questionType: QuestionType.MultipleChoice,
					options: ['A', 'B', 'C', 'D'],
					correctAnswer: '',
				});
			})
		});
	};

	const uploadUnitPdf = async (files: FileList | null) => {
		if (files && files.length > 0 && courseId !== 0 && unitId !== 0) {
			if (files[0].type !== 'application/pdf') {
				toast.error(t('courses.invalid_file_format'));
				return;
			}
			const doc: AdminCourseDocumentDTO = {
				id: 0,
				courseId: courseId ?? 0,
				documentName: files[0].name,
				versionNumber: 1,
				documentTypeCode: '',
				courseUnitId: unitId ?? 0,
				courseLessonId: 0,
				courseActivityId: 0,
				courseAssessmentId: 0,
				documentThumbnail: '',
			};
			const result = await adminAPI.course.uploadCourseDocument(files, doc);
			return result;
		}
	};

	return (
		<div className="bg-white mt-3 rounded-3 border border-quaternary-600" key={unitId}>
			<div className="d-flex justify-content-between align-items-center border-bottom border-quaternary-500 py-3 p-4">
				<h5 className="mb-0 d-flex align-items-center">
					<IconBook className="me-1 icon-27" />
					{t('courses.unit')} {unitIndex + 1}
				</h5>
				<Button
					className="me-n3 btn-ghost-primary btn-sm"
					onClick={() => removeUnit(unitIndex)}
					icon={<IconClose className="icon-22" />}
				>
					{t('courses.remove')}
				</Button>
			</div>
			<div className="pt-3 px-4">
				<Controller
					key={`courseunitname${unitIndex}`}
					control={control}
					name={`courseUnits.${unitIndex}.unitName`}
					render={({ field }) => (
						<FormGroup label={t('courses.unit_name')}>
							<Input
								{...field}
							/>
						</FormGroup>
					)}
				/>

				<Controller
					key={`courseUnits.${unitIndex}.courseDocuments`}
					control={control}
					name={`courseUnits.${unitIndex}.courseDocuments`}
					render={({ field }) => {
						return (
							<FormGroup label={t('courses.unit_pdf')}>
								{field.value?.[0]?.documentName ? (
									<div className="d-flex justify-content-between align-items-center">
										<p className="mb-0">{field.value?.[0]?.documentName}</p>
										<ActionIcon
											title="Delete"
											icon={IconDelete}
											onClick={async () => {
												const deleted = await deleteCourseDocument(field.value?.[0]?.id);
												if (deleted) { field.onChange(); }
											}} />
									</div>
								) : (
									<Input
										type="file"
										id="inputGroupFile04"
										accept="application/pdf"
										aria-describedby="inputGroupFileAddon04"
										aria-label="Upload"
										{...field}
										onChange={async (e) => {
											const res = await uploadUnitPdf(e.target.files);
											field.onChange(res);
										}}
									/>
								)}
							</FormGroup>
						);
					}}
				/>
				<Controller
					key={`courseunitpartnumber${unitIndex}`}
					control={control}
					name={`courseUnits.${unitIndex}.partNumber`}
					render={({ field }) => (
						<FormGroup label={t('courses.unit_part_number')}>
							<Input
								{...field}
							/>
						</FormGroup>
					)}
				/>
				<Controller
					key={`courseunitpagenumber${unitIndex}`}
					control={control}
					name={`courseUnits.${unitIndex}.pageNumbers`}
					render={({ field }) => (
						<FormGroup label={t('courses.pdf_page_range')}>
							<p>{t('courses.use_commas_to_split')}</p>
							<Input
								value={field.value}
								onChange={field.onChange}
							/>
						</FormGroup>
					)}
				/>
				{lessonFields.length > 0 && lessonFields.map((item, index) => {
					const typedItem = item as unknown as AdminCourseLessonDTO;
					return (
						<CourseLessonComponent
							name={`courseUnits.${unitIndex}.courseLessons`}
							key={typedItem.id}
							courseId={courseId}
							unitId={unitId}
							lessonId={typedItem.id}
							lessonIndex={index}
							removeLesson={() => deleteCourseLesson(index)}
						/>
					);
				})}

				{assessmentFields.length > 0 && assessmentFields.map((item, index) => {
					const typedItem = item as unknown as AdminCourseAssessmentDTO;
					if (typedItem.assessmentType === 'exam') {
						return <CourseExam
							key={`assessment-${typedItem.id}`}
							name={`courseUnits.${unitIndex}.courseAssessments`}
							examIndex={index}
							removeExam={() => deleteCourseAssessment(index)}
							lessonParent={false}
							courseId={courseId}
							unitId={unitId}
						/>;
					} else {
						return <CourseAssessmentComponent
							key={`assessment-${typedItem.id}`}
							assessmentIndex={index}
							removeAssessment={() => deleteCourseAssessment(index)}
							name={`courseUnits.${unitIndex}.courseAssessments`}
							lessonParent={false}
						/>;
					}

				})}
			</div>
			<div className="px-4 py-3 border-top border-quaternary-600 d-flex">
				<Button
					className="btn-outline-primary"
					onClick={addCourseLesson}
					icon={<IconAdd className="icon-27" />}
				>
					{t('courses.add_lesson')}
				</Button>

				<Dropdown
					className="btn-outline-primary ms-3"
					icon={<IconAdd className="icon-27" />}
					options={[
						{
							label: t('courses.exam'),
							onClick: () => addCourseExam('exam')
						}
					]}
				>
					{t('courses.add_assessment')}
				</Dropdown>
			</div>
		</div>
	);
};
export default CourseUnitComponent;


