import React from 'react';
import { Button, FormGroup, Input } from '@punchcard/core';
import { IconClose, IconTask } from '@punchcard/core/icons';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface CourseAssessmentComponentProps {
	assessmentIndex: number;
	removeAssessment: (index: number) => void;
	name: string;
	lessonParent: boolean;
}

const CourseAssessmentComponent = (CourseAssessmentComponentProps: CourseAssessmentComponentProps) => {
	const { t } = useTranslation();
	const { control } = useFormContext();
	const { assessmentIndex, removeAssessment, name, lessonParent } = CourseAssessmentComponentProps;

	return (
		<div className={classNames('px-4 py-2 mb-3 rounded-3', lessonParent ? 'bg-quaternary' : 'bg-quaternary-200')}>
			<div className="d-flex justify-content-between align-items-center mb-3">
				<h6 className="mb-0 d-flex align-items-center">
					<IconTask className="me-1 icon-27" />
					{assessmentIndex + 1}: {t('courses.assessment')}
				</h6>
				<Button
					className="btn-ghost-primary me-n3 btn-sm"
					onClick={() => removeAssessment(assessmentIndex)}
					icon={<IconClose className="icon-22" />}
				>
					{t('courses.remove')}
				</Button>
			</div>
			<Controller
				control={control}
				name={`${name}.${assessmentIndex}.assessmentName`}
				defaultValue=""
				render={({ field }) => (
					<FormGroup className="pb-2" label={t('courses.assessment_name')}>
						<Input
							{...field}
							placeholder={t('courses.activity_name')}
						/>
					</FormGroup>
				)}
			/>
			<Controller
				control={control}
				name={`${name}.${assessmentIndex}.gradeValue`}
				render={({ field }) => (
					<FormGroup className="pb-2" label={t('courses.grade_value')}>
						<Input
							{...field}
							placeholder={t('courses.grade_value')}
						/>
					</FormGroup>
				)}
			/>
			<Controller
				control={control}
				name={`${name}.${assessmentIndex}.pageNumbers`}
				defaultValue=""
				render={({ field }) => (
					<FormGroup className="pb-2" label={t('courses.pdf_page_range')}>
						<p>{t('courses.use_commas_to_split')}</p>
						<Input
							{...field}
							placeholder={t('courses.page_numbers')}
						/>
					</FormGroup>
				)}
			/>
		</div>
	);
};
export default CourseAssessmentComponent;


