import React from 'react';
import classNames from 'classnames';
import Radio from '@punchcard/core/components/Radio';

interface IRadio {
	label: string;
	value: string;
	disabled?: boolean;
	selectedAnswer?: boolean;
	correctAnswer?: boolean;
}

interface IExamRadioGroupProps {
	onChange: (v: string) => void;
	options: IRadio[];
	value: string;
	correctValue?: string;
	horizontal?: boolean;
	name?: string;
	ref?: React.RefObject<HTMLDivElement> | null;
	className?: string;
	isStudent: boolean;
}

export function ExamRadioGroup(props: IExamRadioGroupProps) {
	return (
		<React.Fragment>
			{props.options.map((option) => {
				return (
					<Radio
						key={`${props.name}-${option.label}`}
						className={classNames('exam-radio-group form-check-inline', props.className)}
						radioClassName={classNames(
							props.correctValue && {
								'correct': props.correctValue === option.value,
								'incorrect': props.correctValue !== option.value && props.value === option.value,
							},
						)}
						onChange={() => props.onChange(option.value)}
						checked={props.isStudent ? option.value === props.value : option.value === props.correctValue}
						label={option.label}
						disabled={props.isStudent && props.correctValue !== undefined}
						name={props.name}
					/>
				);
			})}
		</React.Fragment>
	);
}

export default IExamRadioGroupProps;
