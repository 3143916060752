import React from 'react';
import { Button, DataTable, Page, PageHero, ActionIcon, LoadingScreen } from '@punchcard/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { useTranslation } from 'react-i18next';
import { IconAdd, IconDownload, IconEdit, IconSchool } from '@punchcard/core/icons';
import { RowInfo } from 'react-table';

const CoursesPage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const { t } = useTranslation();
	const [courseList, setCourseList] = React.useState<AdminCourseDTO[]>([]);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [isCreatingNew, setIsCreatingNew] = React.useState<boolean>(false);
	const [isExporting, setIsExporting] = React.useState<boolean>(false);


	React.useEffect(() => {
		async function getCourseList() {
			const courses = await adminAPI.course.getCourseList();
			if (courses !== null) {
				setCourseList(courses);
			} else {
				toast.error(t('unable_to_retrieve_course_list'));
			}
			setLoading(false);
		}
		getCourseList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const exportExamList = async () => {
		setIsExporting(true);
		await adminAPI.courseExam.exportCourseExams();
		setIsExporting(false);
	};
	const openCourse = (row: RowInfo) => {
		navigate(`/courses/${row.original.id}${location.search}`);
	};

	const openSchools = (row: RowInfo) => {
		navigate(`/courses/${row.original.id}/schools?courses=${location.search}`);
	};

	const newCourse = async () => {
		setIsCreatingNew(v => !v);
		await adminAPI.course.postCreateCourseId(0).then((response) => {
			if (response !== null) {
				navigate(`/courses/${response}`);
			}
		}).finally(() => {
			setIsCreatingNew(false);
		});
	};
	// This is defined here so that the columns array isn't recreated every time the render function is called.
	const columns = [
		{
			Header: 'Course ID',
			accessor: 'id',
			show: false
		},
		{
			Header: t('courses.code'),
			accessor: 'courseNumber',
			width: 120,
		},
		{
			Header: t('courses.course_name'),
			accessor: 'courseName',
		},
		{
			Header: t('courses.schools_with_access'),
			accessor: 'numberOfSchoolsAssigned',
			width: 240,
			Cell: (rowInfo: RowInfo) =>
				<React.Fragment>
					{rowInfo.original.isAllSchoolsAssigned ? t('courses.all_schools_count', { count: rowInfo.original.numberOfSchoolsAssigned }) : rowInfo.original.numberOfSchoolsAssigned}
				</React.Fragment>,
		},
		{
			accessor: 'Actions',
			Cell: (rowInfo: RowInfo) =>
				<React.Fragment>
					<ActionIcon title={t('schools.school_list')} icon={IconSchool} onClick={() => { openSchools(rowInfo); }} />
					<ActionIcon title={t('edit')} icon={IconEdit} onClick={() => { openCourse(rowInfo); }} />
				</React.Fragment>,
			Header: t('action'),
			showClearButton: true,
			id: 'Actions'
		},

	];
	if (loading) {
		return <LoadingScreen />;
	}
	return (
		<Page title={t('courses.course_list')} className="bg-light px-4">
			<PageHero title={t('courses.course_list')} className="justify-content-between">
				<Button onClick={exportExamList} className="btn-outline-secondary me-3" loading={isExporting} icon={<IconDownload />}>{t('navigation.export_exams')}</Button>
				<Button onClick={newCourse} className="btn-primary" loading={isCreatingNew} icon={<IconAdd />}>{t('navigation.create_new')}</Button>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={courseList}
					columns={columns}
					resizable={true}
					sortable={true}
					filterable={true}
					noDataText={t('courses.no_courses_found')}
					loading={loading}
				/>
			</div>
		</Page>
	);
};

export default CoursesPage;